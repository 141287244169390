<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="6">
        <b-card>
          <h3 class="text-primary text-center">
            {{ $t('new_support.title') }}
          </h3>
          <h5 class="text-warning">
            {{ $t('new_support.sub_title') }}
          </h5>
          <hr>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form @submit.prevent="btn_create_support">
              <b-row>
                <b-col>
                  <!-- user full name -->
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="user_data.name"
                      disabled
                    />
                  </b-input-group>
                  <!-- user full name -->
                </b-col>
                <b-col>
                  <!-- user email -->
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="user_data.email"
                      disabled
                    />
                  </b-input-group>
                  <!-- user email -->
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col cols="12">
                  <!-- basic -->
                  <b-form-group>
                    <label for="subject"><span class="text-danger text-bold">*</span> {{ $t('new_support.subject') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="subject"
                      rules="required"
                    >
                      <b-form-input
                        id="subject"
                        v-model="subject"
                        :state="errors.length > 0 ? false: subject != null ? null : null"
                        :placeholder="$t('new_support.subject')"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col cols="12">
                  <b-form-group>
                    <label for="comment"><span class="text-danger text-bold">*</span> {{ $t('new_support.comment') }}</label>
                    <validation-provider
                        #default="{ errors }"
                        name="comment"
                        rules="required"
                    >
                      <quill-editor
                          id="message"
                          :state="errors.length > 0 ? false: comment != null ? true : null"
                          v-model="comment"
                          :options="editorOptions"
                      />
                      <small
                          v-if="errors[0]"
                          class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col cols="12">
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="alert-body">
                      <span>{{ $t('new_support.alert_info_message') }}</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col cols="12">
                  <!-- basic -->
                  <b-form-group
                    :label="$t('new_support.additional')"
                    label-for="attacment"
                  >
                    <b-form-file
                      v-model="additional_files"
                      :placeholder="$t('new_support.additional_files_placeholder')"
                      multiple
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col>
                  <b-button
                    size="sm"
                    variant="primary"
                    type="submit"
                    :disabled="invalid"
                  >
                    <span class="text-nowrap">{{ $t('new_support.btn_send') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol, BForm, BFormFile, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BForm,
    BAlert,
    BButton,
    BFormFile,
    quillEditor,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      user_data: null,
      comment: null,
      subject: null,
      additional_files: null,
      editorOptions: {
        theme: 'snow', // veya 'bubble', istediğiniz temayı seçebilirsiniz
        placeholder: this.$t('new_support.comment'), // Placeholder'ı boş bırakabilirsiniz
      },
    }
  },
  watch: {
  },
  created() {
    this.user_data = getUserData()
  },
  mounted() {},
  methods: {
    btn_create_support() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('comment', this.comment)
          formData.append('company', this.user_data.company_id)
          formData.append('subject', this.subject)
          formData.append('request_user', this.user_data.id)
          if(this.additional_files){
            this.additional_files.forEach(file => { formData.append(file.name, file) })
          }
          useJwt.createSupport(formData)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('new_support.toast_success_title'),
                  icon: 'AlertCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'support_detail', params: { id: response.data.support_id }})
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
  },
}
</script>
<style>
.ql-container {
  min-height: 200px; /* İstediğiniz minimum yüksekliği buraya girin */
}
</style>
